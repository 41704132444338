.App {
  text-align: center;
}

:root {
  --primary-color: #041937;
}

.delete-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--primary-color);
  background-image: url("./Mobil_Pattern_darkblue.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: hard-light;
  background-attachment: fixed;
}

.logo {
  width: 100%;
  height: auto;
}

.request-delete-account-form {
  width: 650px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}

@media screen and (max-width: 700px) {
  .request-delete-account-form {
    width: 300px;
  }
}

.request-delete-account-form h2 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.request-delete-account-form p {
  margin-bottom: 20px;
}

.request-delete-account-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}

.request-delete-account-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.request-delete-account-form input:focus-visible {
  outline: 2px solid var(--primary-color);
}

.error {
  color: #dc3545;
  font-size: 14px;
  font-weight: bold;
}

.request-delete-account-form button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.request-delete-account-form button:hover {
  opacity: 0.8;
}

.spinner-container {
  display: flex;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.confirm-delete-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
